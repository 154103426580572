import React, { useEffect, useRef } from 'react';
import './searchSuggestion.css';

function SearchSuggestion(props) {
  const inputRef = useRef();
  const resultIndex = useRef();

  function onKeyDown(event) {
    // resultIndex.current.focus();
    // console.log("pressed");
    console.log(event);
    const keyPressed = event.key;
    if (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown' || keyPressed === 'Escape') {
      // this code is to scroll properly when arrow keys are pressed
      if (['ArrowUp', 'ArrowDown'].indexOf(event.key) > -1) {
        event.preventDefault();
      }

      // This is to get the array of all the elments inside ul
      const resultItems = Array.from(resultIndex.current.children);
      console.log(resultItems);

      // This is to get the index of the li which ius focused presently.
      const activeResultIndex = resultItems.findIndex((child) => {
        return child.querySelector('div') === document.activeElement;
      });
      // const activeResultIndex = resultItems.indexOf(document.activeElement);
      console.log(activeResultIndex);
      const inputIsFocused = inputRef.current;
      if (keyPressed === 'ArrowUp') {
        console.log('Going up');
        if (inputIsFocused === document.activeElement) {
          console.log(resultItems[0].querySelector('div'));
          resultItems[1].querySelector('div').focus();
          console.log(resultItems[activeResultIndex - 1]);
        } else if (resultItems[activeResultIndex - 1]) {
          resultItems[activeResultIndex - 1].querySelector('div').focus();
        } else {
          inputRef.current.focus();
        }
      }
      if (keyPressed === 'ArrowDown') {
        console.log('Going down');
        if (inputIsFocused === document.activeElement) {
          console.log(resultItems[0].querySelector('div'));
          resultItems[1].querySelector('div').focus();
          console.log(resultItems[activeResultIndex + 1]);
        } else if (resultItems[activeResultIndex + 1]) {
          resultItems[activeResultIndex + 1].querySelector('div').focus();
        } else {
          inputRef.current.focus();
        }
      }
      if (keyPressed === 'Escape') {
        console.log('Escape');
      }
      // if(keyPressed === "Enter"){
      //   console.log("enter");
      // }
    }
    console.log(document.activeElement);
  }

  useEffect(() => {
    if (props.dropDownData.length > 0) {
      document.body.addEventListener('keydown', onKeyDown);
    } else {
      document.body.removeEventListener('keydown', onKeyDown);
    }

    //To remove event listner when the dropdwon is closed
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, [props.dropDownData]);

  return (
    <div>
      <input
        ref={inputRef}
        // tabIndex="0"
        type="text"
        name="name"
        className="input"
        value={props.achieverSelected}
        disabled={props.disabled}
        onChange={(e) => props.handleChange(e)}
        onFocus={props.handleOnFocus}
      />
      {props.searchSuggestion && props.dropDownData.length > 0 ? (
        // <div className="achieverNameOverlay">
        <ul ref={resultIndex} className="achieverNameDiv">
          <li>
            <div
              className="achieverNameClose"
              onClick={props.handleUlClose}
              tabIndex="0"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  props.handleUlClose();
                }
              }}
            >
              X
            </div>
          </li>
          {/* <hr className="achieverNameLine" /> */}
          {props.dropDownData.map((object, achieverIndex) => {
            return (
              <li key={achieverIndex}>
                <div
                  tabIndex={achieverIndex + 1}
                  className="achieverNameUl"
                  onClick={() => props.handleUlClick(object)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      console.log(object);
                      props.handleUlClick(object);
                    }
                  }}
                >
                  {object.achiever_name}
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}

export default SearchSuggestion;
